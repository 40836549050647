import React from 'react';
import profile from './assets/img/profile.jpg';
import linkedin from './assets/img/linkedin.png';
import facebook from './assets/img/facebook.png';
import deviantart from './assets/img/deviantart.png';
import github from './assets/img/github.png';
import stackoverflow from './assets/img/stackoverflow.png';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFF"
    },
    secondary: {
      main: "#FFF"
    }
  }
});
const useStyles = makeStyles({
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});

function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      
      
      <div className="App-right">
        <img src={profile} className="Bg-img" />
      </div>
      <div className="App-left">
        <p className="App-txt">Hello! I am <br/>
         <span className="App-txt-hightlight">Omar Hussain</span>
        </p>
        <p className="App-logo">...</p>
        <p className="App-txt-headline">Freelance Consultant, Mobile Programmer, Fullstack Developer</p>
        <p className="App-txt-summary">Experienced technologist with over 7 years experience working directly with several overseas companies and total of 10 years of experience. Providing end-to-end solutions to clients. Having in depth knowledge on a large number of technologies with firm grip on mobile development. I enjoy sharing knowledge via open source projects in my free time and solving challenges. </p>
        <div className="App-links">
          <p><a alt="Email" target="_blank" href="mailto:omar.theappexpert@gmail.com">Email: omar.theappexpert@gmail.com</a></p>
          <p><a alt="Skype" target="_blank" href="skype:satansly">Skype: satansly</a></p>
          <p><a alt="Phone" target="_blank" href="tel:+923455062435">Phone: +923455062435</a></p>
        </div>
        <div className="App-links">
          <a  alt="DeviantArt" target="_blank" href="https://www.deviantart.com/satansly-dopinhigh"><img className="App-link-img" src={deviantart} /> </a>
          <a  alt="Linkedin" target="_blank" href="https://www.linkedin.com/in/omarhussain/"><img className="App-link-img" src={linkedin} /> </a>
          <a  alt="Facebook" target="_blank" href="https://www.facebook.com/satansly"><img className="App-link-img" src={facebook} /> </a>
          <a  alt="Github" target="_blank" href="https://github.com/satansly"><img className="App-link-img" src={github} /> </a>
          <a  alt="Stackoverflow" target="_blank" href="https://stackoverflow.com/users/652934/omar"><img className="App-link-img" src={stackoverflow} /> </a>
        </div>
      </div>
    </div>
    </ThemeProvider>
  );
}

export default App;
